/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import { doClearWallet, doVerifyNick, saveUser } from '../../../services/UsersService'
import SwitchInput from '../../../components/SwitchInput/SwitchInput'
import { i18n } from '../../../utils/translates/i18n'
import { toast } from 'react-toastify'
import { deactivateTwoFAAdm } from '../../../services/AuthService'
/**
 * props:
 * - data
 * - onSubmit
 */
function UserModal(props) {
  const DEFAULT_USER = {
    nick: '',
    name: '',
    email: '',
    password: '',
    userId: '',
    limitId: 0,
    telegramChat: '',
    ddi: '',
    phone: '',
    isActive: false,
    photo: '',
    passwordSecurity: '',
    status: '',
    binaryQualify: false,
    activity: false,
    userGroupId: 1,
    releaseWithdrawals: false,
  }

  const [error, setError] = useState('')

  const [user, setUser] = useState(DEFAULT_USER)
  const [walletBtc, setWalletBtc] = useState('')
  const [walletUsdt, setWalletUsdt] = useState('')
  const [walletPix, setWalletPix] = useState('')
  // const [walletKes, setWalletKes] = useState('')
  const [nick, setNick] = useState('')
  const [doAlert, setDoAlert] = useState({ show: false, bg: '', msg: '' })

  const btnClose = useRef('')
  const btnSave = useRef('')

  function onSubmit() {
    changeNick().then(success => {
      if (success) {
        const post = {
          ...user,
          wallets: { btc: walletBtc, usdt: walletUsdt, pix: walletPix },
        }
        saveUser(user.id, post)
          .then(result => {
            btnClose.current.click()
            if (props.onSubmit) props.onSubmit(result)
          })
          .catch(err => {
            console.error(err.response ? err.response.data : err.message)
            setError(err.response ? err.response.data : err.message)
          })
      } else {
        toast.error(i18n.t('not_self_register'))
      }
    })
  }

  const clearWallet = async wallet => {
    const success = await doClearWallet(wallet, user.id)
    if (success.status) {
      toast.success(i18n.t('wallet_cleared'))
      btnClose.current.click()
      if (props.onSubmit) props.onSubmit(success)
    }
  }

  const changeNick = async () => {
    if (nick != props.data.nick) {
      const success = await doVerifyNick(nick)
      if (!success.status) {
        setNick(props.data.nick)
        setDoAlert({ show: true, bg: 'danger', msg: i18n.t(success.msg) })
        return false
      } else {
        setUser(p => ({ ...p, nick }))
        setDoAlert({ show: false, bg: '', msg: '' })
        return true
      }
    } else {
      setDoAlert({ show: false, bg: '', msg: '' })
      return true
    }
  }

  function onInputChange(event) {
    setUser(prevState => ({ ...prevState, [event.target.id]: event.target.value }))
  }

  // async function deactivateTwoFA(id) {
  //   const response = await deactivateTwoFAAdm(id)
  //   if (response.status) toast.success(i18n.t('success_update_user'))
  //   else toast.error(i18n.t(response.error))
  // }

  useEffect(() => {
    setUser(DEFAULT_USER)
    setWalletBtc('')
    setWalletUsdt('')
    setWalletPix('')
    // setWalletKes('')
    if (props.data.id) {
      setUser({
        ...props.data,
        name: props.data.fullName,
        status: props.data.status?.id,
        binaryQualify: props.data.binaryQualify?.status,
        userGroupId: props.data.group?.id,
      })
      setNick(props.data.nick)
      if (!!props.data?.wallets?.length) {
        setWalletBtc(props.data.wallets.filter(f => f.cryptoSymbol === 'btc')[0]?.wallet)
        setWalletUsdt(props.data.wallets.filter(f => f.cryptoSymbol === 'usdt')[0]?.wallet)
        setWalletPix(props.data.wallets.filter(f => f.cryptoSymbol === 'pix')[0]?.wallet)
      }
      // if (props.data.mobileProvider?.length > 0)
      //   setWalletKes(`+${props.data.ddi} ${props.data.phone} (${props.data.mobileProvider})`)
    }
  }, [props])

  return (
    <div
      className="modal fade"
      id="modalUser"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {i18n.t('edit')} {i18n.t('user')}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            {doAlert.show && <div className={`alert alert-${doAlert.bg}`}>{doAlert.msg}</div>}
            <div className="form-group">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <img
                      src={
                        user.photo && user.photo !== 'user.png'
                          ? `${process.env.REACT_APP_API_URL}/images/${user.photo}`
                          : '/img/user.png'
                      }
                      crossOrigin="anonymous"
                      alt="user"
                      className={`img-thumbnail img-fluid shadow`}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    {['developer', 'manager', 'admlotteries', 'support'].includes(props.rule) && (
                      <div className="col-md mb-3">
                        <div className="form-group">
                          <label htmlFor="nick">{i18n.t('nick')}:</label>
                          <input
                            type="text"
                            id="nick"
                            className="form-control"
                            onChange={e =>
                              setNick(
                                e.target.value
                                  .toLowerCase()
                                  .replace(' ', '')
                                  .replace(/[^a-zA-Z0-9\s]/g, '')
                              )
                            }
                            onBlur={changeNick}
                            value={nick}
                            placeholder="nick"
                          />
                        </div>
                      </div>
                    )}
                    {(['developer', 'finance'].includes(props.rule) ||
                      (['support'].includes(props.rule) && parseInt(user?.userGroupId) != 9)) && (
                      <div className="col-md mb-3">
                        <div className="form-group">
                          <label htmlFor="type">{i18n.t('type')}:</label>
                          <select
                            id="userGroupId"
                            className="form-select"
                            value={user.userGroupId}
                            onChange={onInputChange}>
                            {/* <option value="2">{i18n.t('better')}</option> */}
                            <option value="3">{i18n.t('client')}</option>
                            <option value="9">{i18n.t('voucher')}</option>
                            <option value="10">{i18n.t('leader_pendent')}</option>
                            <option value="12">{i18n.t('leader_approved')}</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  {['developer', 'finance'].includes(props.rule) && (
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="name">{i18n.t('name')}:</label>
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            onChange={onInputChange}
                            value={user.name}
                            placeholder="User's full name"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {['developer', 'support'].includes(props.rule) && (
                    <div className="row">
                      <div className="col-5 mb-3">
                        <div className="form-group">
                          <label htmlFor="email">{i18n.t('email')}:</label>
                          <input
                            type="text"
                            id="email"
                            className="form-control"
                            onChange={onInputChange}
                            value={user.email}
                            placeholder="user@email.com"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="ddi">{i18n.t('ddi')}:</label>
                          <input
                            type="number"
                            id="ddi"
                            className="form-control"
                            onChange={onInputChange}
                            value={user?.ddi}
                            placeholder={i18n.t('ddi')}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="phone">{i18n.t('phone')}:</label>
                          <input
                            type="number"
                            id="phone"
                            className="form-control"
                            onChange={onInputChange}
                            value={user?.phone}
                            placeholder={i18n.t('phone')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {['developer', 'manager', 'admlotteries', 'support'].includes(props.rule) && (
                    <div className="row">
                      <div className="col-12 mb-3">
                        <button className="btn btn-danger" onClick={() => deactivateTwoFA(user.id)}>
                          <i className="fa fa-qrcode me-2"></i> {i18n.t('two_fa_deactivate')}
                        </button>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              {['developer', 'finance'].includes(props.rule) && (
                <div className="card">
                  <div className="card-header bg-light py-2">{i18n.t('wallets').toUpperCase()}:</div>
                  <div className="card-body p-3 pb-0">
                    <div className="row">
                      {/* <div className="col-md-6 mb-3">
                        <div className="input-group">
                          <span className="btn btn-warning">{i18n.t('btc').toUpperCase()}:</span>
                          <input
                            type="text"
                            className="form-control"
                            onChange={e => setWalletBtc(e.target.value)}
                            value={walletBtc}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-6 mb-3">
                        <div className="input-group">
                          <span className="btn btn-warning">{i18n.t('pix').toUpperCase()}:</span>
                          <input
                            type="text"
                            className="form-control"
                            onChange={e => setWalletPix(e.target.value)}
                            value={walletPix}
                          />
                          <button className="btn btn-danger" onClick={() => clearWallet('pix')}>
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="input-group">
                          <span className="btn btn-warning">{i18n.t('usdt').toUpperCase()}:</span>
                          <input
                            type="text"
                            className="form-control"
                            onChange={e => setWalletUsdt(e.target.value)}
                            value={walletUsdt}
                          />
                          <button className="btn btn-danger" onClick={() => clearWallet('usdt')}>
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      {/* <div className="col-md-6 mb-3">
                        <div className="input-group">
                          <span className="btn btn-warning">{i18n.t('kes').toUpperCase()}:</span>
                          <div className="form-control">{walletKes}</div>
                          <button className="btn btn-danger" onClick={() => clearWallet('kes')}>
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              )}
              {['developer', 'finance'].includes(props.rule) && (
                <div className="row">
                  {/* <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <SwitchInput
                      id="activity"
                      text={`${i18n.t('user_active')}?`}
                      onChange={onInputChange}
                      isChecked={user.activity || false}
                    />
                  </div>
                </div> */}
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <SwitchInput
                        id="binaryQualify"
                        text={`${i18n.t('qualify_binary')}?`}
                        onChange={onInputChange}
                        value={user.binaryQualify}
                        isChecked={user.binaryQualify}
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <SwitchInput
                      id="releaseWithdrawals"
                      text={`${i18n.t('release_withdrawals')}?`}
                      onChange={onInputChange}
                      isChecked={user.releaseWithdrawals || false}
                    />
                  </div>
                </div> */}
                </div>
              )}
            </div>
            {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
          </div>
          <div className="modal-footer">
            {error && <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>}
            <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
              {i18n.t('save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserModal
