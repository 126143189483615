import React from 'react'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import Dashboard from './private/Dashboard/Dashboard'
import Limits from './private/Limits/Limits'
import Orders from './private/Orders/Orders'
// import Tutorial from "./private/Tutorial/Tutorial";
import Users from './private/Users/Users'
import Login from './public/Login/Login'
import Binary from './private/Binary/Binary'
import Calls from './private/Calls/Calls'
import ReadCall from './private/Calls/ReadCall'
import CredDeb from './private/CredDeb/CredDeb'
// import CreateEditLottery from "./private/Games/CreateEditLottery";
// import Games from "./private/Games/Games";
// import Lotteries from "./private/Games/Lotteries";
// import ResultsLottery from "./private/Games/ResultsLottery";
import QuotasPayment from './private/QuotasPayment/QuotasPayment'
// import ReportGame from "./private/Reports/ReportGame";
import Profile from './private/Settings/Profile'
import Symbols from './private/Symbols/Symbols'
// import CreateTutorial from "./private/Tutorial/CreateTutorial";
// import EditTutorial from "./private/Tutorial/EditTutorial";
// import TelegramChat from "./private/Users/TelegramChat";
import Withdrawals from './private/Withdrawals/Withdrawals'
import WithdrawalsFiles from './private/Withdrawals/WithdrawalsFiles'
import Logout from './public/Login/Logout'
import Kyc from './private/Users/Kyc'
import Prolabore from './private/Prolabore'
import Incomes from './private/Binary/Incomes'
import Menu from './components/Menu/Menu'

function Routes() {
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => {
          return sessionStorage.getItem('token') ? (
            <>
              <Menu />
              {children}
            </>
          ) : (
            <Redirect to="/logout" />
          )
        }}
      />
    )
  }

  return (
    <BrowserRouter>
      <Route path="/" exact>
        <Login />
      </Route>
      <Route path="/logout" exact>
        <Logout />
      </Route>

      <PrivateRoute path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path="/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute path="/users">
        <Users />
      </PrivateRoute>
      {/* <PrivateRoute path="/kyc">
        <Kyc />
			</PrivateRoute> */}

      <PrivateRoute path="/orders">
        <Orders />
      </PrivateRoute>
      {/* <PrivateRoute path="/games">
        <Games />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/tutorial">
        <Tutorial />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/tutorials/edit/:id">
        <EditTutorial />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/tutorials/create">
        <CreateTutorial />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/lotteries">
        <Lotteries />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/lottery/edit/:id">
        <CreateEditLottery />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/lottery/results/:id">
        <ResultsLottery />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/lottery/create">
        <CreateEditLottery />
      </PrivateRoute> */}
      <PrivateRoute path="/sonosfac">
        <CredDeb />
      </PrivateRoute>
      <PrivateRoute path="/sonosprolabore">
        <Prolabore />
      </PrivateRoute>
      <PrivateRoute path="/binary">
        <Binary />
      </PrivateRoute>
      {/* <PrivateRoute path="/incomes">
        <Incomes />
      </PrivateRoute> */}
      <PrivateRoute path="/dailygains">
        <QuotasPayment />
      </PrivateRoute>
      <PrivateRoute path="/withdrawals">
        <Withdrawals />
      </PrivateRoute>
      <PrivateRoute path="/withdrawalsfiles">
        <WithdrawalsFiles />
      </PrivateRoute>
      <PrivateRoute path="/calls">
        <Calls />
      </PrivateRoute>
      <PrivateRoute path="/readcall">
        <ReadCall />
      </PrivateRoute>
      {/* <PrivateRoute path='/symbols'>
				<Symbols />
			</PrivateRoute> */}
      {/* <PrivateRoute path="/reports/:id">
        <ReportGame />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/limits">
        <Limits />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/telegram-chat">
        <TelegramChat />
      </PrivateRoute> */}
    </BrowserRouter>
  )
}

export default Routes
